<template>
  <div>
    <!-- <github-corner class="github-corner" /> -->
    <v-container class="container--fluid grid-list-md text-center">
      <panel-group @handleSetLineChartData="handleSetLineChartData" />
      <v-row dense>
        <v-col cols="12">
          <app-widget class="card-box" title="Line Chart">
            <v-btn
              slot="widget-header-action"
              icon
            >
              <v-icon class="primary--text text--darken-1">mdi-flare</v-icon>
            </v-btn>
            <option-chart
              slot="widget-content"
              height="350px"
              width="100%"
              :chart-data="getChartOption('line')"
            />
          </app-widget>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <app-widget class="card-box" title="Raddar Chart">
            <option-chart
              slot="widget-content"
              height="300px"
              width="100%"
              :chart-data="getChartOption('raddar')"
            />
          </app-widget>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <app-widget class="card-box" title="Pie Chart">
            <option-chart
              slot="widget-content"
              height="300px"
              width="100%"
              :chart-data="getChartOption('pie')"
            />
          </app-widget>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <app-widget class="card-box" title="Bar Chart">
            <option-chart
              slot="widget-content"
              height="300px"
              width="100%"
              :chart-data="getChartOption('bar')"
            />
          </app-widget>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          lg="7"
          sm="12"
          cols="12"
        >
          <app-widget
            class="card-box"
            title="Projects"
            padding-hide
          >
            <plain-table slot="widget-content" />
          </app-widget>
        </v-col>
        <v-col
          lg="5"
          sm="12"
          cols="12"
        >
          <app-widget
            class="card-box"
            title="Orders"
            padding-hide
          >
            <plain-table-order slot="widget-content" />
          </app-widget>
        </v-col>
      </v-row>
      <WelcomeDialog :dialog.sync="dialog"/>
    </v-container>
  </div>
</template>

<script>
import {
  lineChartData,
  getLineChartOption,
  getRaddarChartOption,
  getPieChartOption,
  getBarChartOption,
} from '@/api/mock';
import * as Activity from '@/api/mock_activity';

// import GithubCorner from '@/views/Dashboard/GithubCorner.vue';
import PanelGroup from '@/views/adminApp/Dashboard/PanelGroup.vue';
import OptionChart from '@/views/adminApp/Dashboard/OptionChart.vue';
import PlainTable from '@/views/adminApp/Dashboard/PlainTable.vue';
import PlainTableOrder from '@/views/adminApp/Dashboard/PlainTableOrder.vue';
import AppWidget from '@/views/widget/AppWidget.vue';
import WelcomeDialog from "@/components/WelcomeDialog"
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  components: {
    // GithubCorner,
    PanelGroup,
    OptionChart,
    PlainTable,
    PlainTableOrder,
    AppWidget,
    WelcomeDialog
  },
  data: () => ({
    lineChartData: lineChartData.newVisitis,
  }),
  computed: {
    activity() {
      return Activity.default.getActivity();
    },
    ...mapGetters([
      'welcomeDialog'
    ]),
    dialog: {
      get() {
        return this.welcomeDialog
      },
      set(val) {
        this.$store.dispatch('SetWelcomeDialog', val)
      }
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
    getChartOption(option) {
      switch (option) {
        case 'line':
          return getLineChartOption(this.lineChartData);
        case 'raddar':
          return getRaddarChartOption({ animationDuration: 3000 });
        case 'pie':
          return getPieChartOption();
        case 'bar':
          return getBarChartOption({ animationDuration: 6000 });
        default:
          return {};
      }
    },
  },
};
</script>
<style scoped>
.card-box {
  box-shadow: 0px 0px 0px 2px rgb(94 86 105 / 10%) !important;
}
</style>

